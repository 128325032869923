import { User, UserCredential } from "firebase/auth";
import { CustomerData } from "../../common/CustomerModel";
import { fetchCustomerInfo } from "../../common/utils/FetchUtils";
import { auth } from "../../common/config/firebase/FirebaseConfig";

class UserStore {
  readonly getLoggedInUser = function (): User | undefined {
    auth.onAuthStateChanged((user) => {
      if (user) {
        return user;
      }else{
        console.error("No user found");
        return undefined;
      }
    });
    const currentUser = auth.currentUser;
    const userString = localStorage.getItem("@user");
    const localUser = userString ? JSON.parse(userString) : undefined;
    if (currentUser && localUser && currentUser.uid === localUser.uid) {
      return currentUser;
    }
    return undefined;
  };
  readonly getLoggedInCustomer = function (): CustomerData | undefined {
    const customerString = localStorage.getItem("@cust");
    return customerString ? JSON.parse(customerString) : undefined;
  };

  readonly fetchAndGetCustomer = function (
    phoneNumber: string,
    organizationId: string,
    tokenPromise: Promise<string>
  ): Promise<CustomerData> {
    return fetchCustomerInfo(
      organizationId,
      tokenPromise
    ).then((customers) => {
      if (customers.length == 0) {
        throw new Error("No user/customer with this phoneNumber.");
      } else if (customers.length > 1) {
        throw new Error("Multiple user/customer with this phoneNumber.");
      }
      return customers[0];
    });
  };

  readonly signOutUser = async function () {
    localStorage.clear();
    auth.signOut();
  };

  readonly setLoggedInUser = async function (user: User) {
    localStorage.setItem("@user", JSON.stringify(user));
  };
}

export const LoggedUserStore = new UserStore();
