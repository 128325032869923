import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Header from './components/Header';
import { Footer } from './components/Footer';
import { getDisplayType } from './common/utils/utils';
import { Signin } from './modules/signin/Signin';
import { UserContext } from './modules/signin/userContext';
import { useContext, useEffect, useState } from 'react';
import UserData, { UserDataProps } from './modules/userData/UserData';
import { NoOrganization } from './components/noOrganization/NoOrganization';
import { LoggedUserStore } from './modules/signin/userStore';
import { CustomerContext } from './modules/userData/customerContext';
import { IntlProvider } from 'react-intl';
import { messages } from './i18n/messages';
import { auth } from './common/config/firebase/FirebaseConfig';
import { signOut } from './common/config/signOut';
import { Spinner } from 'react-bootstrap';


export const Body = () => {
  const [locale, setLocale] = useState<"en" | "hi">("hi")
  return (
    <div className={getDisplayType() == 'Desktop' ? 'container appBody' : 'appBody'}>

      <IntlProvider messages={messages[locale]} locale={locale}>
        <BrowserRouter>
          <Routes>
            <Route index path='/narahiDairy' element={<App organizationId="narahi-dairy-org" />} />
            <Route index path='/' element={<App organizationId="narahi-dairy-org" />} />
            <Route index path='' element={<App organizationId="narahi-dairy-org" />} />
            <Route element={<NoOrganization />} />
          </Routes>
        </BrowserRouter>
      </IntlProvider>
    </div>
  )
}

function App({ organizationId, ...props }: UserDataProps) {
  const [firstLoad, setFirstLoad] = useState<boolean>(true)
  const userType = useContext(UserContext)!!;
  const { customer, setCustomer } = useContext(CustomerContext)!!;

  const { user, setUser } = userType
  auth.onAuthStateChanged((loggedUser) => {
    if (loggedUser) {
      if (!loggedUser.phoneNumber) {
        console.error("customer does not have mobile number, Phone number is must to have")
      } else {
        if (userType && user === undefined) {
          setUser(loggedUser)
        } else if (userType && user?.uid !== loggedUser.uid) {
          signOut()
          auth.signOut()
        }
      }
    } else {
      console.error("No user found");
    }
    setFirstLoad(false)
  });
  useEffect(() => {
    if (user && user?.phoneNumber) {
      let loggedCustomer = LoggedUserStore.getLoggedInCustomer();
      if (loggedCustomer) {
        setCustomer(loggedCustomer)
      }
      else {
        LoggedUserStore.fetchAndGetCustomer(user.phoneNumber, organizationId, user.getIdToken())
          .then(loggedCustomer => {
            if (loggedCustomer) {
              setCustomer(loggedCustomer)
            } else {
              console.error("No Customer profile found")
            }
          })

      }
    }
  }, [user])
  return (
    <div className='App'>
      {
        !firstLoad ?
          <>{
            user && (user.email || user.phoneNumber) ?
            <>
              <Header />
              <UserData organizationId={organizationId} />
              <Footer />
            </>
            : <Signin organizationId={organizationId} />
          }
          </>
          : <div className="d-flex flex-column align-items-center justify-content-center">
            <div className="row">
              <Spinner variant="primary" style={{ maxWidth: "15rem", minInlineSize: "10rem", height: "10rem" }} />
            </div>
            <div className="row label label-default">
              <h2>
                Loading...
              </h2>
            </div>
          </div>
      }

    </div>
  );
}

