import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import logoImg from '../common/photo/narahi_dairy-log.png'
import { signOut } from '../common/config/signOut';
import { Button } from 'react-bootstrap';

const Header = () => (
    <div>
        <Navbar expand="lg" className="bg-primary header-navbar" >
            <Container>
                <Navbar.Brand href="#home">
                    <img src={logoImg} className='logoStyle' /> Narahi Dairy
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <Nav.Link href="#home">Home</Nav.Link>
                        <Nav.Link onClick={signOut}> Sign Out
                        </Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>

    </div>
)

export const NonUserHeader = () => (
<Navbar expand="lg" className="bg-primary header-navbar" >
            <Container>
                <Navbar.Brand href="#home">
                    <img src={logoImg} className='logoStyle' />
                </Navbar.Brand>
            </Container>
        </Navbar>
);

export default Header;